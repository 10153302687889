
.prehome-login {
    width: 100%;

    &__sign-in, &__sign-up {
        padding: $space_x11 $space_x4;
        position: relative;
        .title {
            @include font-size(32px);
            color: $color-claro;
            margin-bottom: $space_x5;
            transition: 500ms linear;
            transition-delay: 200ms;
            transform: translateX(-10%);
            opacity: 0;
            width: fit-content;
    
            strong {
                color: $color-secundario;
            }
            &.appear {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }
    &__sign-in {
        background-color: $color-primario;
        
        .logo{
            display: none;
        }
    }
    &__sign-up {
        background-color: $color-claro; 
        background-image: url(../../images/plane_mb.jpg), url(../../images/tower_icon_light.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left top, right bottom; 
        @include image-2x-mixed('../../images/plane_mb@2x.jpg','../../images/tower_icon_light@2x.png', contain);
        @include image-3x-mixed('../../images/plane_mb@3x.jpg','../../images/tower_icon_light@3x.png', contain);
        position: relative;
        z-index: 2;

        .title {
            color: $color-primario;
        }
    }

    //768
    @include media(tablet) {
        &__sign-in, &__sign-up {
            padding: $space_x15 $space_x11;
        }
        &__sign-up {
            background-image: url(../../images/plane_mb.jpg);
            background-size: contain;
            background-position: left top; 
            @include image-2x('../../images/plane_mb@2x.jpg', contain);
            @include image-3x('../../images/plane_mb@3x.jpg', contain);
        }
    }
    //992
    @include media(desktop-s) {

        &__sign-up {
            background-size: cover;
            @include image-2x('../../images/plane_mb@2x.jpg', cover);
            @include image-3x('../../images/plane_mb@3x.jpg', cover);
        }
    }

    //1200
    @include media(desktop-m) {
        display: flex;
        background-image: linear-gradient(to bottom, #fff , transparent 40%),url(../../images/plane_dt.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center; 
        @media (min--moz-device-pixel-ratio: 1.3),(-o-min-device-pixel-ratio: 2.6/2),(-webkit-min-device-pixel-ratio: 1.3),
            (min-device-pixel-ratio: 1.3),(min-resolution: 1.3dppx) {
                background-image: linear-gradient(to bottom, #fff , transparent 40%),url(../../images/plane_dt@2x.jpg);
        }

        &__sign-in, &__sign-up {
            .title {
                @include font-size(36px);
                font-weight: 300;
                white-space: pre;
    
                strong {
                    font-weight: 500;
                }
            }
        }

        &__sign-in {
            padding: $space_x6 $space_x8 0 $space_x10;
            width: 35%;
            background-color: get-opacity($color-primario, .95);

            .logo {
                display: block;
                width: 180px;
                max-width: 180px;
                margin-bottom: $space_x11;

                img{
                    width: 100%;
                }
            }
        }
        &__sign-up {
            background-image: none;
            background-color: transparent;
            width: 65%;
            padding: $space_x12 $space_x8;
            padding-right: 200px;
        }
    }
    //1780
    @include media(desktop-xl) {

        &__sign-in {
            padding: $space_x6 $space_x8 0 200px;

            .logo {
                width: 220px;
                max-width: 220px;
                margin-bottom: $space_x14;
            }
        }
        &__sign-up {
            padding: $space_x15 $space_x8;
        }
    }
}