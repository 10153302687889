.whatsapp-accordion {
    scroll-margin-top: $space_x3;
    margin-bottom: $space_x3;
    box-shadow: 7px 9px 13px #00000012;

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-size(18px);
        font-weight: 400;
        width: 100%;
        margin: 0 auto;
        height: 65px;
        color: $color-primario;
        scroll-margin-top: $space_x3;
    }
    &__title {
        @include font-size(22px);
        color: $color-primario;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
    }
    &__indicator {
        display: grid;
        place-items: center;
        height: 100%;
        padding: 0 $space_x3;
        color: $color-claro;
        transition: 200ms linear;
        cursor: pointer;
        width: 100%;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        &.open {
            svg {
                transform: rotate(180deg);
            }
        }
        svg {
            width: $space_x2;
            height: $space_x2;
            transition: 200ms linear;
        }
    }

    &__panel {
        overflow: hidden;

        &.open {
            visibility: visible;
        }
        &.closed {
            height: 0;
            visibility: hidden;
        }
    }

    @include media(desktop-s) {

        margin-bottom: 35px;

        &__title {
            @include font-size(18px);
        }
    }
}
