.header_prehome_HeaderAside {
    display: none;

    //-----  1200px  ------//
    @include media(desktop-m) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 35%;
        min-height: 100vh;
        padding: $space_x8 $space_x2 $space_x8 $space_x10;
        background-color: $color-primario;
        $top-height: 119px;

        &__logo {
            width: 175px;
            flex: 0.1;

            img {
                width: 100%;
            }
        }

        &__title {
            flex: 0.8;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            color: $color-blanco;

            h1 {
                @include font-size(47px);
                line-height: 55px;
                font-weight: 400;
                margin-bottom: 1.25rem;

                strong {
                    color: $color-secundario;
                    font-weight: 400;
                }
            }
            h2 {
                @include font-size(19px);
                font-weight: 300;
            }
        }
        &__language-links-dt {
            flex: 0.1;
            display: flex;
            color: $color-blanco;
            text-align: center;

            .navigation-dt {
                display: flex;
                align-items: center;

                .links-list {
                    display: flex;
                    align-items: center;
                    @include font-size(17px);
                    margin-right: 1rem;

                    a {
                        &:first-child {
                            margin-right: 0.5rem;
                        }
                        &.active {
                            color: $color-secundario;
                        }
                    }
                    span {
                        font-size: 14px;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }

    //-----  1780px  ------//
    @include media(desktop-xl) {
        padding: $space_x13 $space_x2 $space_x13 $space_x13;

        &__logo {
            width: 245px;
        }
        &__title {
            h1 {
                @include font-size(57px);
                line-height: 70px;
            }
            h2 {
                @include font-size(24px);
            }
        }
        &__language-links-dt {
            .navigation-dt {
                .links-list {
                    @include font-size(20px);
                }
            }
        }
    }
}
