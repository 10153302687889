.whatsapp-info {

    width: 100%;
    margin: 0 auto;
    color: $color-p-text;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 0;
    position: relative;

    &:last-child {
        margin-bottom: 30px;
    }

    &.partners, &.incoming, &.sports, &.corporate, &.administration {

        background-color: $color-claro;

        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 100%;
            position: absolute;
            left: 0;
        }
    }

    &.partners::before {
        background-color: $color-variante-cuatro;
    }

    &.incoming::before {
        background-color: $color-variante-uno;
    }

    &.sports::before {
        background-color: $color-variante-tres;
    }
    &.corporate::before {
        background-color: $color-variante-dos;
    }
    &.administration::before {
        background-color: $color-madre;
    }

    img {
        width: 50px;
        object-fit: cover;
        border-radius: 50%;
        margin-left: 5px;
    }

    .text {
        display: flex;
        flex-direction: column;
        width: 55%;
        color: $color-primario;

        .title {
            font-weight: 600;
            padding-bottom: 5px;
        }

        .position {
            font-size: 12px;
            padding-bottom: 5px;
        }

        .online, .offline {
            font-size: 9px;
            text-transform: uppercase;
            display: flex;
            align-items: center;

            &::before {
                content: '';
                display: flex;
                width: 5px;
                height: 5px;
                margin-right: 3px;
                border-radius: 50%;
            }
        }

        .online {
            color: $color-positive;

            &::before {
                background-color: $color-positive;
            }
        }

        .offline {
            color: $color-p-text;

            &::before {
                background-color: $color-p-text;
            }
        }
    }

    .button {
        border-radius: 50%;
        background-color: transparent;
        color: #3DD598;
        align-self: flex-end;
        transition: transform 500ms ease;
        
        &:hover {
            transform: scale(1.2);
        }
    }

    @include media(desktop-s) {

        width: 90%;
        margin: 0 auto 20px;
        padding: 10px 0;

        img {
            width: 65px;
        }

        .button {
            align-self: center;
        }
    }
}