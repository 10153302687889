.subscribe-form {
  display: flex;
  align-items: center;
  background-color: $color-claro;
  position: relative;

  input {
      padding: $space_half $space;
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
  }

  button {
      width: 40px;
      height: 30px;
      max-height: 32px;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: $color-secundario;

      &:hover {
          background-color: get-opacity($color-secundario, 0.7);
      }
      img {
          width: 50%;
      }
  }
  .error-span {
      position: absolute;
      color: $color-negative;
      top: 100%;
  }
  .sended-span {
        @extend .error-span;
        color: $color-positive;

        &.loading {
            color: $color-claro;
        }
  }
}