@import "./utils/bootstrap_reboot";
@import "./utils/breakpoints";
@import "./utils/grids";
@import './utils/font-face.scss';
@import "./variables/mixins";
@import "./variables/config";
@import "./utils/base";
@import "./utils/animations";
@import './utils/classes.scss';

//Componentes externos a App.js
@import "./components/LoadingSpinner";
@import "./components/Loader";

.prehome-tower-travel {
  //Componentes inernos a App.js
  @import "./components/ScrollSnap";
  @import "./components/Burger";
  @import "./components/LanguageSelector";
  @import "./components/HeaderMobile";
  @import "./components/HeaderAside";
  @import "./components/Footer";
  @import "./components/OurProductsCard";
  @import "./components/Socials";
  @import "./components/ContactForm";
  @import "./components/SignInForm";
  @import "./components/SignUpForm";
  @import "./components/SubscribeForm.scss";
  @import "./components/Whatsapp";
  @import "./components/WhatsappInfo";
  @import "./components/WhatsappAccordion";
  @import './components/WhatsappLoader.scss';


  //Pages
  @import "./pages/Login";
  @import "./pages/Home";
  @import "./pages/About";
  @import "./pages/Contact";
  @import "./pages/NotFound.scss";

  //Contenedor para el header y las paginas, el footer queda fuera
  .header-page-wrapper {
    @media screen and (min-width: $lg) {
      display: flex;

      .page {
        width: 65%;

        &::-webkit-scrollbar {
          width: 0;
        }
    
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

        @include media(desktop-m) {
          min-height: 100vh;
          max-height: 100vh;
          overflow-y: scroll;
        }
      }
    }
  }
}
