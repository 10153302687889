.contact-form {
    .input-group {
        position: relative;
        margin-bottom: 3rem;

        $labelHeight: 24px;

        label {
            display: block;
            @include font-size(18px);
            color: $color-primario-dark;
            height: $labelHeight;
        }

        input[type='text'],
        input[type='email'],
        textarea {
            @include font-size(18px);
            color: $color-primario;
            height: 3rem;
            padding-left: $space_half;
            border: 0;
            outline: 0;
            background: transparent;
            border-bottom: solid 1px $color-primario-dark;

            &:focus {
                border-bottom: solid 2px $color-secundario;
            }
        }

        textarea {
            font-family: $Fuente1;
            resize: none;
            max-height: 3rem;
            min-width: 100%;
            scrollbar-width: none; //Scroll bar no visible para FireFox
            &::-webkit-scrollbar {
                //Scroll bar no visible para el resto de browsers
                display: none;
            }

            &:focus {
                padding-top: $space;
                height: 10rem;
                max-height: 10rem;
                scrollbar-width: unset;

                &::-webkit-scrollbar {
                    display: unset;
                    width: 6px;
                }
            }
            //Con un state le damos esta clase y se la dejamos para que nunca se achique al perder el foco y no dificulte
            //la experiencia de usario al querer clickear el boton de submit con el mouse
            &.has-been-active {
                padding-top: $space;
                height: 10rem;
                max-height: 10rem;
                scrollbar-width: unset;

                &::-webkit-scrollbar {
                    display: unset;
                    width: 6px;
                }
            }
        }
        .error-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: $labelHeight;
            width: $labelHeight;
            color: $color-negative;
            position: absolute;
            top: 0;
            right: 0;
        }
        .error-msg {
            color: $color-negative;
            height: 24px;
            position: absolute;
            bottom: -28px;
            left: 0;
            // border: solid 1px red;
        }
    }
    .submit-btn {
        width: 145px;
        height: 50px;
    }
    .sended-form {
        display: block;
        color: $color-positive;
        margin: 0 $space $space 0;
        opacity: 0;
        min-width: fit-content;
        animation: fade 500ms forwards linear;

        &.loading {
            color: $color-primario;
        }
        &.error {
            color: $color-negative;
        }

        @keyframes fade {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    @include media(desktop-m) {
        .input-group {
            label {
                @include font-size(14px);
            }
        }
        .submit-btn {
            @include font-size(14px);
            width: 120px;
            height: 35px;
        }
    }
    @include media(desktop-l) {
        .input-group {
            label {
                @include font-size(18px);
            }
        }
        .submit-btn {
            @include font-size(16px);
            width: 144px;
            height: 48px;
        }
    }
}
