//---- NOTO SANS ----//
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/notosans/NotoSans-Regular.ttf');
}
@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/notosans/NotoSans-RegularItalic.ttf');
}
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/notosans/NotoSans-Bold.ttf');
}
@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../../fonts/notosans/NotoSans-BoldItalic.ttf');
}