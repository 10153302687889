.header_prehome_mobile {
    background-color: $color-primario;
    $top-height: 119px;

    &__top {
        display: grid; 
        place-items: center;   
        min-height: $top-height;
        box-shadow: 0 0.5px 0 $color-blanco;
        position: relative;


        .logo {
            width: 160px;

            @media (min-width: $sm ) {
                width: 180px;
            }

            img {
                width: 100%;
            }
        }

        .burger {
            position: absolute;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
        }        
    }

    &__options {
        width: 100vw;
        min-height: calc(100vh - #{$top-height});
        background-color: $color-primario;
        padding-bottom: 2rem;
        opacity: 0;
        transform: translateX(-100%);
        transition: 200ms ease-in-out;
        position: absolute;
        top: $top-height;
        z-index: 3;

        &.open { 
            opacity: 1;
            transform: translateX(0);
        }

        .navigation {
            margin: 15vh 0 6rem;  

            .links-list {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 2rem;

                    &:last-child {
                        margin-bottom: 0;  
                    }

                    a{  
                        @include font-size(22px);
                        font-weight: 500;
                        color: $color-blanco;

                        &.active {
                            color: $color-secundario;
                        }
                    }
                }
            }
        }
        .socials-wrapper {
            width: 210px;
            max-width: 210px;
            margin: 0 auto 4rem;

            @media (min-width: $sm ) {
                width: 240px;
                max-width: 240px;
            }
        }
    }

    //-----  1200px  ------//
    @include media(desktop-m) {
        display: none;
    }
}