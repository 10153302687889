.whatsapp {

    &__btn {

        position: fixed;
        bottom: 2rem;
        right: 2rem;
        z-index: 99;
        background-color: transparent;
        cursor: pointer;
        color: #3DD598;
        width: 50px;
        height: 50px;

        &.hidden {

            display: none;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }

        .background-circle {
            display: block;
            width: 22px;
            height: 22px;
            background-color: $color-claro;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.whatsapp__modal {
    margin-top: 10%;
    overflow-y: scroll;
    height: 90%;
    max-height: 600px;
    background-color: $color-primario;
    position: relative;
    z-index: 99;
    
    &::-webkit-scrollbar {
        display: none;
    }

    .close-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        font-size: 18px;
        color: $color-claro;
        background-color: transparent;
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 60px;
        background-color: $color-primario;
    }
        
    &__text {

        background-color: $color-primario;
        color: $color-claro;
        text-align: center;
        padding: 20px 45px;
        
        .title {
            font-size: 22px;
            padding: 15px 0;
        }

        .copy {
            line-height: 22px;
        }
    }

    &__search {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 0;
        position: relative;

        .search-input {

            width: 70%;
            height: 38px;
            padding: 10px;
            outline: none;
            border-top: 1px solid $color-primario;
            border-bottom: 1px solid $color-primario;
            border-right: 1px solid $color-primario;
            border-left: none;
            position: relative;
            border-radius: 0;
        }

        .search-btn {
            background-image: url('../../images/icons/Lupa.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 35px;
            height: 38px;
            background-color: transparent;
            border-top: 1px solid $color-primario;
            border-bottom: 1px solid $color-primario;
            border-left: 1px solid $color-primario;
            cursor: pointer;
        }

        .delete-btn {
            position: absolute;
            right: 13%;
            background-color: transparent;
            color: $color-negative;
            cursor: pointer;
        }
    }

    &__info {

        width: 80%;
        margin: 10px auto;

        .partners {
            background-color: lighten($color: $color-variante-cuatro, $amount: 15%);

            &::before {
                background-color: $color-variante-cuatro;
            }
        }

        .incoming {
            background-color: lighten($color: $color-variante-uno, $amount: 20%);

            &::before {
                background-color: $color-variante-uno;
            }
        }

        .sports {
            background-color: lighten($color: $color-variante-tres, $amount: 40%);

            &::before {
                background-color: $color-variante-tres;
            }
        }
        .corporate {
            background-color: lighten($color: $color-variante-dos, $amount: 40%);

            &::before {
                background-color: $color-variante-dos;
            }
        }
        .administration {
            background-color: lighten($color: $color-madre, $amount: 40%);

            &::before {
                background-color: $color-madre;
            }
        }

        .partners, .incoming, .sports, .corporate, .administration {

            position: relative;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 8px;
                position: absolute;
                bottom: 0;
            }
        }

        &:last-child {
            padding-bottom: 5px;
        }

        .not-found, .error-msg {

            color: $color-primario;
            margin: 35px 0 55px;
        }

        .no-contacts {
            color: $color-primario;
            padding: 95px 0;
        }
    }

    .white-bg-container {
        background-color: $color-claro;
    }

    //500px
    @media(min-width: 500px) {
        max-width: 500px;
        margin: 90px auto;
        height: 80%;
    }

    //landscape
    @media(max-width: 992px) and (orientation: landscape) {
        margin: 50px auto;
    }

    //768px
    @include media(tablet) {

        max-width: 600px;
        max-height: 665px;

        &__text {

            .title {
                font-size: 26px;
                padding: 15px 0 20px;
            }
        }

        &__search {

            padding: 35px 0;
            
            .search-input {
                width: 60%;
            }

            .delete-btn {
                right: 18%;
            }
        }

        &__info {
            
            width: 65%;

            &:last-child {
                padding-bottom: 20px;
            }
        }
    }

    //992px
    @include media(desktop-s) {
        max-width: 800px;
        max-height: 80vh; //new
        margin: 10vh auto; //new


        &__text {

            .title {
                font-size: 36px;
                padding: 15px 0 25px;
            }
        }

        &__search {

            padding: 45px 0;
        }

        &__info {

            &:last-child {
                padding-bottom: 25px;
            }
        }
    }

    //1200px
    @include media(desktop-m) {

        &__text {

            .copy {
                font-size: 18px;
                width: 77%;
                margin: 0 auto;
            }
        }

        &__search {

            .delete-btn {
                right: 18.5%;
            }
        }
    }

    //1480
    @include media(desktop-l) {

        max-width: 900px;
        max-height: 700px;

        &__search {

            .delete-btn {
                right: 19%;
            }
        }
    }
}

.ReactModal__Overlay--after-open {
    z-index: 99;
}
