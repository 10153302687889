.our-products-card {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 300px;
    height: 325px;
    padding: 56px 39px 33px;
    margin: 1rem 0;
    background-color: $color-blanco;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    $generalTransition: 300ms ease-in-out;
    transition: $generalTransition;
    cursor: pointer !important;

    &.variante-uno {
        background-color: $color-variante-uno;
    }
    &.variante-dos {
        background-color: $color-variante-dos;
    }
    &.variante-tres {
        background-color: $color-variante-tres;
    }
    &.variante-cuatro {
        background-color: $color-variante-cuatro;

        .our-products-card__text.hovered {
            color: $color-primario;
        }
        .our-products-card__link.hovered {
            color: $color-primario;

            .arrow-line,
            .arrow-line:after,
            .arrow-line:before {
                background-color: $color-primario;
            }
        }
    }

    &:first-child {
        margin-left: 0.25rem;
    }
    &__icon {
        margin-bottom: $space_x3;
        width: 122px;
        height: 36px;
    }
    &__text {
        @include font-size(15px);
        color: $color-p-text;
        font-weight: 300;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &.hovered {
            color: $color-blanco;
        }
    }
    &__link {
        margin-top: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: max-content;
        @include font-size(15px);
        color: $color-secundario;
        font-weight: 300;

        &.hovered {
            color: $color-blanco;

            .arrow-container {
                width: 51px;

                .arrow-line {
                    background-color: $color-blanco;

                    &:after,
                    &:before {
                        background-color: $color-blanco;
                    }
                }
            }
        }

        .arrow-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 14px;
            margin-left: 12px;
            transition: $generalTransition;

            .arrow-line {
                width: 100%;
                height: 2px;
                background-color: $color-secundario;
                border-radius: 6px;
                position: relative;

                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    border-radius: 6px;
                    background-color: $color-secundario;
                }
                &::after {
                    width: 2px;
                    height: 10px;
                    top: 2px;
                    right: 0;
                    transform: rotate(45deg) translate(-4px, 0px);
                }
                &::before {
                    width: 2px;
                    height: 10px;
                    top: -9px;
                    right: 0;
                    transform: rotate(315deg) translate(-3px, -1px);
                }
            }
        }
    }
    @include media(desktop-m) {
        min-width: 230px;
        max-width: 230px;
        height: 250px;
        padding: 40px 30px 25px;

        &__icon {
            margin-bottom: $space_x2;
            width: 100px;
            height: 31px;
        }
        &__text {
            @include font-size(13px);
        }
        &__link {
            @include font-size(13px);

            .arrow-container {
                .arrow-line {
                    &::after {
                        height: 7px;
                        top: 3px;
                    }
                    &::before {
                        height: 7px;
                        top: -7px;
                    }
                }
            }
        }
    }
    @include media(desktop-xl) {
        min-width: 300px;
        max-width: 300px;
        height: 325px;
        padding: 56px 39px 33px;

        &__icon {
            margin-bottom: $space_x3;
            width: 122px;
            height: 36px;
        }
        &__link {
            @include font-size(15px);
            .arrow-container {
                .arrow-line {
                    &::after {
                        height: 10px;
                        top: 2px;
                    }
                    &::before {
                        height: 10px;
                        top: -9px;
                    }
                }
            }
        }
    }

    //2300px
    @include media(desktop-xxxl) {
        min-width: 375px;
        max-width: 375px;
        height: 400px;
        padding: 56px 50px 33px;
    }
}
