
.sign-up-form {
    background-color: transparent;

    .input-group {
        position: relative;
        margin-bottom: 3rem;
        height: fit-content;

        &:last-of-type {
            margin-bottom: 5rem;
        }

        $labelHeight: 24px;

        label {
            display: block;
            @include font-size(18px);
            color: $color-primario;
            height: $labelHeight;
        }

        input[type=text],input[type=email],input[type=password] {
            @include font-size(18px);
            color: $color-primario;
            height: 3rem;
            padding-left: $space_half;
            border: 0;
            outline: 0;
            background: transparent;
            border-bottom: solid 1px $color-primario;

            &:focus {
                border-bottom: solid 2px $color-secundario;
            }
        }   

        .error-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: $labelHeight;
            width: $labelHeight;
            color: $color-negative;
            position: absolute;
            top: 0;
            right: 0;
        }
        .error-msg {
            color: $color-negative;
            height: 24px;
            position: absolute;
            bottom: -28px;
            left: 0;
        }
    }
    .submit-btn {
        @include font-size(18px);
        font-family: $Fuente1;
        font-weight: 500;
        width: 100%;
        height: 50px;
        // @include buttonSubmit($color-secundario, $color-claro);
    }

    //1200
    @include media(desktop-m) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .input-group {
            width: 45%;
        }

        .submit-btn {
            width: 45%;
        }
    }
}