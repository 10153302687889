.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-primario;
    position: relative;
    overflow: hidden;

    &__image {
        min-width: 100vw;
        max-width: 100%;
        height: auto;
        object-fit: cover;
    }

    &__content-top {
        position: absolute;
        top: 15%;
        max-width: 80%;

        h1 {
            @include font-size(26px);
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            filter: drop-shadow(0 0 2px $color-claro);
        }
    }
    &__content-bottom {
        position: absolute;
        bottom: 15%;
        max-width: 80%;

        h2 {
            @include font-size(26px);
            font-weight: 700;
            text-align: center;
            filter: drop-shadow(0 0 2px $color-claro);
        }
        p {
            @include font-size(14px);
            text-align: center;
            filter: drop-shadow(0 0 2px $color-claro);
        }
    }

    @include media(tablet-p-only) {
        max-height: calc(100vh + $header-top-height);

        &__image {
            max-width: unset;
            max-height: calc(100vh + $header-top-height);
        }
    }

    //1200
    @include media(desktop-m) {


        &__image {
            object-position: center;
            min-height: calc(100vh + $header-top-height);
            max-height: calc(100vh + $header-top-height);
        }

        &__content-top {
            top: 10vh;
            max-width: unset;

            h1 {
                @include font-size(36px);
            }
        }
        &__content-bottom {
            bottom: 2vh;
            max-width: 80%;

            h2 {
                @include font-size(36px);
                margin-bottom: $space;
            }
            p {
                @include font-size(20px);
                max-width: 70%;
                margin: 0 auto;
            }
        }
    }
    //1780
    @include media(desktop-xl) {
        
    }
}
