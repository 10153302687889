::selection {
    background-color: $color-secundario;
    color: $color-claro;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: $color-primario;
}
::-webkit-scrollbar-thumb {
    background: $color-secundario;
}

:root {
    --main-font-size: 14px;
  }
  
@include media(tablet) {
    :root {
        --main-font-size: 18px;
    }
}
@include media(desktop-s) {
    :root {
        --main-font-size: 18px;
    }
}

@include media(desktop-m) {
    :root {
        --main-font-size: 14px;
    }
}

@include media(desktop-ml) {
    :root {
        --main-font-size: 15px;
    }
}

@include media(desktop-l) {
    :root {
        --main-font-size: 15px;
    }
}

@include media(desktop-xl) {
    :root {
        --main-font-size: 16px;
    }
}
@include media(desktop-xxl) {
    :root {
        --main-font-size: 18px;
    }
}
@include media(desktop-xxxl) {
    :root {
        --main-font-size: 22px;
    }
}
html, body {
    overflow-x: hidden;
    font-family: $Fuente1;
    font-size: var(--main-font-size);
    scroll-behavior: smooth;
    //Solo para Firefox, soporta solo estas propiedas solamente para modificar la barra de scroll
    scrollbar-color: $color-secundario $color-primario; /* thumb and track color */
}
body {
    overflow: hidden;
}

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
 body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
}