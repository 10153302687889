@use "sass:math";

.whatsapp__modal__info {
    
        .whatsapp-loader {

                height: 300px;
                background-color: $color-claro;
                display: flex;
                justify-content: center;
                align-items: center;
            
                .card-loader {
                    display: inline-block;
                    position: relative;
                    width: 80px;
                    height: 80px;
                  }
                  .card-loader div {
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    background: $color-secundario;
                    border-radius: 50%;
                    animation: card-loader 1.2s linear infinite;
                  }
                  .card-loader div:nth-child(1) {
                    animation-delay: 0s;
                    top: 37px;
                    left: 66px;
                  }
                  .card-loader div:nth-child(2) {
                    animation-delay: -0.1s;
                    top: 22px;
                    left: 62px;
                  }
                  .card-loader div:nth-child(3) {
                    animation-delay: -0.2s;
                    top: 11px;
                    left: 52px;
                  }
                  .card-loader div:nth-child(4) {
                    animation-delay: -0.3s;
                    top: 7px;
                    left: 37px;
                  }
                  .card-loader div:nth-child(5) {
                    animation-delay: -0.4s;
                    top: 11px;
                    left: 22px;
                  }
                  .card-loader div:nth-child(6) {
                    animation-delay: -0.5s;
                    top: 22px;
                    left: 11px;
                  }
                  .card-loader div:nth-child(7) {
                    animation-delay: -0.6s;
                    top: 37px;
                    left: 7px;
                  }
                  .card-loader div:nth-child(8) {
                    animation-delay: -0.7s;
                    top: 52px;
                    left: 11px;
                  }
                  .card-loader div:nth-child(9) {
                    animation-delay: -0.8s;
                    top: 62px;
                    left: 22px;
                  }
                  .card-loader div:nth-child(10) {
                    animation-delay: -0.9s;
                    top: 66px;
                    left: 37px;
                  }
                  .card-loader div:nth-child(11) {
                    animation-delay: -1s;
                    top: 62px;
                    left: 52px;
                  }
                  .card-loader div:nth-child(12) {
                    animation-delay: -1.1s;
                    top: 52px;
                    left: 62px;
                  }
                  @keyframes card-loader {
                    0%, 20%, 80%, 100% {
                      transform: scale(1);
                    }
                    50% {
                      transform: scale(1.5);
                    }
                  }
            }
        
}