.language-selector {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 50%;

    .btn-lang {
        display: flex;
        align-items: center;
        padding: 0;
        cursor: pointer;
        transition: 100ms all;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border-radius: 50%;
        

        &:not(:last-child){
            margin-right: 15px;
        }

        &.active {
            box-shadow: 0 0 6px 1px #000;
        }
        &:hover {
            transform: scale(1.25);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    @include media(desktop-m){
        align-items: center;
        width: fit-content;
        margin: 0;

        .btn-lang {
            width: 20px;
            height: 20px;
            &.active {
                filter: none;
                box-shadow: 0 0 12px 1px #000;
            }
        }
    }
}