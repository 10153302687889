.scroll-snap-container {
  display: flex;
  //PROPIEDADES DE SCROLL
  scroll-behavior: smooth;
  overflow-x: scroll;
  overflow-y: hidden;
  cursor: grab;

  scrollbar-width: none; //Scroll bar no visible para FireFox
  &::-webkit-scrollbar {
      //Scroll bar no visible para el resto de browsers
      display: none;
  }
  &.mandatory {
      -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  }
  &.proximity {
      -ms-scroll-snap-type: x proximity;
      scroll-snap-type: x proximity;
  }
  //Para aplicar el falso column gap
  @for $i from 1 through 100 {
      &.gap-#{$i} {
          > *:not(:last-child) {
              margin-right: $i + px;
          }
      }
  }
  //Para aplicar padding left
  @for $i from 1 through 20 {
      &.pl_mobile-#{$i} {
          padding-left: $i + rem;
      }
  }
  //Para aplicar padding left
  @for $i from 1 through 20 {
      &.pr_mobile-#{$i} {
          padding-left: $i + rem;
      }
  }

  > * {
      scroll-snap-align: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  //768px
  @media (min-width: 768px) {
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pl_tablet-#{$i} {
              padding-left: $i + rem;
          }
      }
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pr_tablet-#{$i} {
              padding-left: $i + rem;
          }
      }
  }
  //992px
  @media (min-width: 992px) {
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pl_s-#{$i} {
              padding-left: $i + rem;
          }
      }
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pr_s-#{$i} {
              padding-left: $i + rem;
          }
      }
  }

  //1200px
  @media (min-width: 1200px) {
      scroll-behavior: auto;
      &.mandatory {
          -ms-scroll-snap-type: none;
          scroll-snap-type: none;
      }
      &.proximity {
          -ms-scroll-snap-type: none;
          scroll-snap-type: none;
      }
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pl_m-#{$i} {
              padding-left: $i + rem;
          }
      }
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pr_m-#{$i} {
              padding-left: $i + rem;
          }
      }
  }

  //1480px
  @media (min-width: 1480px) {
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pl_l-#{$i} {
              padding-left: $i + rem;
          }
      }
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pr_l-#{$i} {
              padding-left: $i + rem;
          }
      }
  }
  //1780px
  @media (min-width: 1780px) {
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pl_xl-#{$i} {
              padding-left: $i + rem;
          }
      }
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pr_xl-#{$i} {
              padding-left: $i + rem;
          }
      }
  }
  //1920px
  @media (min-width: 1920px) {
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pl_xxl-#{$i} {
              padding-left: $i + rem;
          }
      }
      //Para aplicar padding left
      @for $i from 1 through 20 {
          &.pr_xxl-#{$i} {
              padding-left: $i + rem;
          }
      }
  }
}