
.sign-in-form  {
    background-color: transparent;

    .input-group {
        position: relative;
        margin-bottom: 3rem;
        height: fit-content;

        &:last-of-type {
            margin-bottom: 5rem;
        }

        $labelHeight: 24px;

        label {
            display: block;
            @include font-size(18px);
            color: $color-claro;
            height: $labelHeight;
        }

        input[type=text],input[type=email],input[type=password] {
            @include font-size(18px);
            color: $color-claro ;
            height: 3rem;
            padding-left: $space_half;
            border: 0;
            outline: 0;
            background: transparent;
            border-bottom: solid 1px $color-claro;

            &:focus {
                border-bottom: solid 2px $color-secundario;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus, 
            &:-webkit-autofill:active  {
                -webkit-text-fill-color: $color-claro;
                -webkit-box-shadow: 0 0 0 30px $color-primario inset !important;
                box-shadow: 0 0 0 30px $color-primario inset !important;
            }
        }   

        .error-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: $labelHeight;
            width: $labelHeight;
            color: $color-negative;
            position: absolute;
            top: 0;
            right: 0;
        }
        .error-msg {
            color: $color-negative;
            height: 24px;
            position: absolute;
            bottom: -28px;
            left: 0;
        }
        .watch-pass {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-claro;
            position: absolute;
            bottom: 10px;
            right: 5px;
            cursor: pointer;
        }
        .forgot-pass {
            height: 24px;
            position: absolute;
            bottom: -32px;
            left: 0;
            color: $color-secundario;
            transition: 200ms linear;
            margin-top: $space_half;

            &:hover {
                text-decoration: underline;
            }

            &.move-right {
                position: absolute;
                transform: translateX(125%);
            }
        }
    }
    .submit-btn {
        @include font-size(18px);
        font-family: $Fuente1;
        font-weight: 500;
        width: 100%;
        height: 50px;
        // @include buttonSubmit($color-secundario, $color-claro);
    }
    // 1200px
    @include media(desktop-m) {
        $colorA: get-opacity($color-primario, .98);

        .input-group  {
            input[type=text],input[type=email],input[type=password] {
                &:-webkit-autofill,
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active  {
                    -webkit-box-shadow: 0 0 0 30px $colorA inset !important;
                    box-shadow: 0 0 0 30px $colorA inset !important;
                }
            }

        }
    }
}

